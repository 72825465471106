import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  currentUserSelector,
  loginRequest,
  updateToken,
  me
} from "src/plugins/redux/reducers/AuthReducer";
import {
  EditUser,
  uploadImageToS3,
} from "src/plugins/redux/reducers/UsersReducers";

import {
  setHeaderTitle,
  setHeaderActionBtns,
  setHeaderArrowBack,
  setShowSearch,
} from "src/plugins/redux/reducers/HeaderReducer";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import toast from "react-hot-toast";
import { PatternFormat } from 'react-number-format';
import Cookies from 'js-cookie';
import UpgradePremium from "src/pages/Users/UpgradePremium";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Divider, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function Users() {
  const navigate = useNavigate();
  const currentUrl = location.pathname;
  const [showMyProfile, setshowMyProfile] = useState<boolean | null>(currentUrl.includes('profile'));
  const dispatch = useDispatch();

  const currentUser = useSelector(me);
  console.log(currentUser);
  const [logoFile, setLogoFile] = useState(null);
  const [showUpgradeModal, setShowUpdagradeModal] = useState<boolean | null>(false);

  console.log(currentUser);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useForm();

  const [changePassword, setChangePassword] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedCurrentUserId, setSelectedCurrentUserId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [profileUpdated, setProfileUpdated] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState(currentUser?.phone_number);

  const logoFileInputRef = useRef(null);

  useEffect(() => {
    dispatch(setHeaderTitle("Profile"));
    dispatch(setHeaderActionBtns(false));
    dispatch(setHeaderArrowBack(false));
    dispatch(setShowSearch(false));
  }, []);

  useEffect(() => {
    setSelectedCompany(currentUser?.company_id);
    setSelectedCurrentUserId(currentUser?.id);
    setLogoFile(currentUser?.company_logo);
    console.log("🚀 ~ Users ~ currentUser testttttttttt:", currentUser?.test)
  }, [currentUser]);



  const getJwtData = (token: string): any => {
    const jwt = token.split('.');
    return JSON.parse(Buffer.from(jwt[1], 'base64').toString());
  }
  const handleEditUser = async (data: any) => {
    if (data.password !== data.password_confirmation && changePassword) {
      toast.error("New password does not match");
      return;
    }

    setIsLoading(true);
    data.oldEmail = currentUser?.email;
    data.company_id = selectedCompany;
    data.company_name = currentUser?.company_name;
    data.verified = true;
    data.role_id = currentUser?.role_id;
    data.id = selectedCurrentUserId;
    if (logoFile instanceof File) {
      const company_logo_url = await dispatch(
        uploadImageToS3({ file: logoFile, dirName: "company_logo" })
      );
      data.company_logo = company_logo_url.payload;
      setLogoFile(company_logo_url.payload);
    }
    if (!changePassword) {
      delete data.password;
      delete data.password_confirmation;
    }
    const response = await dispatch(EditUser(data));
    if (response.payload.success) {
      toast.success("Profile Updated!");
    } else {
      if (changePassword) {
        toast.error("The Password Entered Does Not Match Your Current Paswword");
      } else {
        toast.error('Error Updating Profile');
      }
    }

    setProfileUpdated(true);
    dispatch(updateToken(response.payload.token));
    setIsLoading(false);
  };

  const handleLogoInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setLogoFile(file);
      };
      reader.readAsDataURL(file);
    }
    setProfileUpdated(false);
  };

  const toggleChangePassword = () => {
    setChangePassword(!changePassword);
  };

  const token = Cookies.get('session');

  return (
    <div className="row">
      <div className="col-6 col-md-6">
        <form onSubmit={handleSubmit(handleEditUser)}>
          <div className="row mb-6">
              <label className="w-100">Upload Company Logo</label>
            <div className="col-8 col-md-8 d-flex">

              {!logoFile ? (
                <label className="btn btn-pill btn-dark">
                  <input
                    name="logo_file"
                    id="logo_file"
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    className="form-control d-none"
                    ref={logoFileInputRef}
                    onChange={handleLogoInputChange}
                  />
                  <i className="la la-upload" style={{ cursor: "pointer" }}></i>
                  Browse
                </label>
              ) : (
                <>
                <label className="d-flex align-items-center gap-2">
                  <img
                    src={logoFile instanceof File ? URL.createObjectURL(logoFile) : logoFile}
                    alt="Logo"
                    style={{ maxWidth: "100px", cursor: "pointer" }}
                  />
                  <input
                    name="logo_file"
                    id="logo_file"
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    className="form-control d-none"
                    ref={logoFileInputRef}
                    onChange={handleLogoInputChange}
                    style={{ display: "none" }}
                  />
                </label>
                <i
                className="fa fa-times fa-lg text-custom-primary ml-5 mt-15"
                onClick={() => {
                  setLogoFile(null);
                  setProfileUpdated(false);
                }}
              ></i>
              </>
              )}

            </div>
          </div>
          <div className="row mb-6">
            <div className="col-8 col-md-8">
              <label className="w-100">Company Name</label>
              <input
                {...register("company_name")}
                name="company_name"
                id="company_name"
                type="text"
                className="form-control rounded-pill"
                defaultValue={currentUser?.company_name}
                placeholder="Title"
                disabled={true}
              />
            </div>
          </div>
          <div className="row mb-6">
            <div className="col-8 col-md-8">
              <label className="w-100">Email*</label>
              <input
                {...register("email", {
                  required: true,
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                })}
                name="email"
                id="email"
                type="email"
                className="form-control rounded-pill"
                defaultValue={currentUser?.email}
                placeholder="Email"
                onChange={() => setProfileUpdated(false)}
              />
            </div>
          </div>
          {errors?.email && (
            <p style={{ color: "red" }}>Please enter a valid email</p>
          )}
          {(currentUser?.role_id === 3 || currentUser?.role_id == 2) && (
            <>
              <div className="row mb-6">
                <div className="col-8 col-md-8">
                  <label className="w-100">First Name*</label>
                  <input
                    {...register("first_name", { required: true })}
                    name="first_name"
                    id="first_name"
                    type="text"
                    className="form-control rounded-pill"
                    defaultValue={currentUser?.first_name}
                    placeholder="First Name"
                    onChange={() => setProfileUpdated(false)}
                  />
                </div>
              </div>
              <div className="row mb-6">
                <div className="col-8 col-md-8">
                  <label className="w-100">Last Name*</label>
                  <input
                    {...register("last_name", { required: true })}
                    name="last_name"
                    id="last_name"
                    type="text"
                    className="form-control rounded-pill"
                    defaultValue={currentUser?.last_name}
                    placeholder="Last Name"
                    onChange={() => setProfileUpdated(false)}
                  />
                </div>
              </div>
              <div className="row mb-6">
                <div className="col-8 col-md-8">
                  <label className="w-100">Phone Number</label>
                  <PatternFormat
                    name="phone_number"
                    {...register("phone_number")}
                    className="form-control rounded-pill"
                    type="tel"
                    format="+1 (###) ###-####"
                    mask="_"
                    defaultValue={currentUser?.phone_number}
                    onValueChange={value => setPhoneNumber(value.formattedValue)}
                  />
                </div>
              </div>
            </>
          )}

          <div id="password-editor" className="row mb-6">
            <div className="col-8 col-md-8">
              <button
                type="button"
                className="btn btn-pill"
                onClick={toggleChangePassword}
              >
                Change Password
              </button>
              <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_17_4538)">
                  <path d="M15.2317 9.77167L16.2283 10.7683L6.41333 20.5833H5.41667V19.5867L15.2317 9.77167V9.77167ZM19.1317 3.25C18.8608 3.25 18.5792 3.35833 18.3733 3.56417L16.3908 5.54667L20.4533 9.60917L22.4358 7.62667C22.8583 7.20417 22.8583 6.52167 22.4358 6.09917L19.9008 3.56417C19.6842 3.3475 19.4133 3.25 19.1317 3.25V3.25ZM15.2317 6.70583L3.25 18.6875V22.75H7.3125L19.2942 10.7683L15.2317 6.70583V6.70583Z" fill="#081324" />
                </g>
                <defs>
                  <clipPath id="clip0_17_4538">
                    <rect width="26" height="26" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>

          {changePassword && (
            <>
              <div className="row mb-6">
                <div className="col-8 col-md-8">
                  <label className="w-100">Current Password</label>
                  <div style={{ position: "relative" }}>
                    <input
                      {...register("current_password", { required: true })}
                      name="current_password"
                      id="current_password"
                      type={showCurrentPassword ? "text" : "password"}
                      className="form-control rounded-pill "
                      placeholder="********"
                      onChange={() => setProfileUpdated(false)}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-6">
                <div className="col-8 col-md-8">
                  <label className="w-100">
                    New Password{" "}
                    <small className="text-mute">Hard Password</small>
                  </label>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <input
                      {...register("password", {
                        required: true,
                        pattern:
                          "/^(?=.*[!@#$%^&*])(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/",
                      })}
                      name="password"
                      id="password"
                      type={showPassword ? "text" : "password"}
                      className="form-control rounded-pill"
                      placeholder="********"
                      onChange={() => setProfileUpdated(false)}
                    />
                    <InputAdornment>
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  </div>
                </div>
              </div>

              <div className="row mb-6">
                <div className="col-8 col-md-8">
                  <label className="w-100">Retype New Password</label>
                  <div style={{ position: "relative" }}>
                    <input
                      {...register("password_confirmation", { required: true })}
                      name="password_confirmation"
                      id="password_confirmation"
                      type={showPassword ? "text" : "password"}
                      className="form-control rounded-pill"
                      onChange={() => setProfileUpdated(false)}
                      placeholder="********"
                    />
                    <InputAdornment>
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  </div>
                </div>
              </div>
              <span className="text-danger">
                {errors.password?.message}
              </span>
            </>
          )}

          <div className="row mb-6">
            <div className="col-8 col-md-8">
              <button
                type="submit"
                className="btn btn-pill btn-dark"
                disabled={isLoading || profileUpdated}
              >
                Save Changes
              </button>
            </div>
          </div>
        </form>
      </div>
      {currentUser?.role_id === 3 && (
        <>
          <div className="col-md-3 align-self-end">
            <div className="pricing-container">
              <div className="header">
                <strong>Limited Access</strong>
              </div>
              <div className="price">
                <span>
                  <sup className="currency">$</sup><span className="figure">0.00</span> <sup className="cent"></sup> <sup className="frequency"></sup>
                </span>
                <div className='details'>Per Month</div>
              </div>
              <div className="itemsWrapper">
                <div className="line-before includes">This includes:</div>
                <ul className="items">
                <li className='item'><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM9.42857 15.3921L5.5 11.4636L6.74929 10.2143L9.42857 12.8936L15.2507 7.07143L16.5047 8.31757L9.42857 15.3921Z" fill="#2D99CD" />
                  </svg>
                    Limited Access for 1 User</li>
                  <li className='item'><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM9.42857 15.3921L5.5 11.4636L6.74929 10.2143L9.42857 12.8936L15.2507 7.07143L16.5047 8.31757L9.42857 15.3921Z" fill="#2D99CD" />
                  </svg>
                    View, Sort & Filter Your RFPs</li>
                  <li className="item"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM9.42857 15.3921L5.5 11.4636L6.74929 10.2143L9.42857 12.8936L15.2507 7.07143L16.5047 8.31757L9.42857 15.3921Z" fill="#2D99CD" />
                  </svg>
                    Unlimited Submissions</li>
                  <li className="item"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM9.42857 15.3921L5.5 11.4636L6.74929 10.2143L9.42857 12.8936L15.2507 7.07143L16.5047 8.31757L9.42857 15.3921Z" fill="#2D99CD" />
                  </svg>
                    View Submissions</li>
                  <li className="item"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM9.42857 15.3921L5.5 11.4636L6.74929 10.2143L9.42857 12.8936L15.2507 7.07143L16.5047 8.31757L9.42857 15.3921Z" fill="#2D99CD" />
                  </svg>
                    Submit Products and RFP Resources</li>
                  <li className="item unchecked"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM9.42857 15.3921L5.5 11.4636L6.74929 10.2143L9.42857 12.8936L15.2507 7.07143L16.5047 8.31757L9.42857 15.3921Z" fill="#A8B5C2" />
                  </svg>
                    10-Second Product Submissions (with Saved Products)</li>
                  <li className="item unchecked"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM9.42857 15.3921L5.5 11.4636L6.74929 10.2143L9.42857 12.8936L15.2507 7.07143L16.5047 8.31757L9.42857 15.3921Z" fill="#A8B5C2" />
                  </svg>
                    Manage Submissions (view, edit & delete)</li>
                  <li className="item unchecked"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM9.42857 15.3921L5.5 11.4636L6.74929 10.2143L9.42857 12.8936L15.2507 7.07143L16.5047 8.31757L9.42857 15.3921Z" fill="#A8B5C2" />
                  </svg>
                    My Products (stored product portfolio)</li>
                  <li className="item unchecked"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM9.42857 15.3921L5.5 11.4636L6.74929 10.2143L9.42857 12.8936L15.2507 7.07143L16.5047 8.31757L9.42857 15.3921Z" fill="#A8B5C2" />
                  </svg>
                    Shared Product Portfolio (across your company)</li>
                  <li className="item unchecked"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM9.42857 15.3921L5.5 11.4636L6.74929 10.2143L9.42857 12.8936L15.2507 7.07143L16.5047 8.31757L9.42857 15.3921Z" fill="#A8B5C2" />
                  </svg>
                    Unlimited Stored Products and Product Assets</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-3 align-self-end">
            <div className="pricing-container">
              <div className="header">
                <strong>Premium Access</strong>
              </div>
              <div className="price">
                <span>
                  <sup className="currency">$</sup><span className="figure">83.25</span> <sup className="frequency">/ Per Month</sup>
                </span>
                <div className='details'>Billed Yearly</div>
              </div>
              <div className="itemsWrapper">
                <div className="line-before includes">This includes:</div>
                <ul className="items">
                <li className='item'><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM9.42857 15.3921L5.5 11.4636L6.74929 10.2143L9.42857 12.8936L15.2507 7.07143L16.5047 8.31757L9.42857 15.3921Z" fill="#2D99CD" />
                  </svg>
                    Premium Access for 1 User</li>
                  <li className='item'><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM9.42857 15.3921L5.5 11.4636L6.74929 10.2143L9.42857 12.8936L15.2507 7.07143L16.5047 8.31757L9.42857 15.3921Z" fill="#2D99CD" />
                  </svg>
                    View, Sort & Filter Your RFPs</li>
                  <li className="item"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM9.42857 15.3921L5.5 11.4636L6.74929 10.2143L9.42857 12.8936L15.2507 7.07143L16.5047 8.31757L9.42857 15.3921Z" fill="#2D99CD" />
                  </svg>
                    Unlimited Submissions</li>
                  <li className="item"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM9.42857 15.3921L5.5 11.4636L6.74929 10.2143L9.42857 12.8936L15.2507 7.07143L16.5047 8.31757L9.42857 15.3921Z" fill="#2D99CD" />
                  </svg>
                    View Submissions</li>
                  <li className="item"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM9.42857 15.3921L5.5 11.4636L6.74929 10.2143L9.42857 12.8936L15.2507 7.07143L16.5047 8.31757L9.42857 15.3921Z" fill="#2D99CD" />
                  </svg>
                    Submit Products and RFP Resources</li>
                  <li className="item"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM9.42857 15.3921L5.5 11.4636L6.74929 10.2143L9.42857 12.8936L15.2507 7.07143L16.5047 8.31757L9.42857 15.3921Z" fill="#2D99CD" />
                  </svg>
                    10-Second Product Submissions (with Saved Products)</li>
                  <li className="item"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM9.42857 15.3921L5.5 11.4636L6.74929 10.2143L9.42857 12.8936L15.2507 7.07143L16.5047 8.31757L9.42857 15.3921Z" fill="#2D99CD" />
                  </svg>
                    Manage Submissions (view, edit & delete)</li>
                  <li className="item"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM9.42857 15.3921L5.5 11.4636L6.74929 10.2143L9.42857 12.8936L15.2507 7.07143L16.5047 8.31757L9.42857 15.3921Z" fill="#2D99CD" />
                  </svg>
                    My Products (stored product portfolio)</li>
                  <li className="item"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM9.42857 15.3921L5.5 11.4636L6.74929 10.2143L9.42857 12.8936L15.2507 7.07143L16.5047 8.31757L9.42857 15.3921Z" fill="#2D99CD" />
                  </svg>
                    Shared Product Portfolio (across your company)</li>
                  <li className="item"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM9.42857 15.3921L5.5 11.4636L6.74929 10.2143L9.42857 12.8936L15.2507 7.07143L16.5047 8.31757L9.42857 15.3921Z" fill="#2D99CD" />
                  </svg>
                    Unlimited Stored Products and Product Assets</li>
                  <li className="mt-2">
                    {(currentUser?.is_premium || currentUser?.is_paid) && (
                      <button
                        onClick={() => {
                          window.open('https://billing.stripe.com/p/login/9AQ02h0xS9ba1BS7ss', '_blank');
                          setshowMyProfile(true);
                        }}
                        className="btn btn-custom w-100"
                      >
                        Manage Subscription
                      </button>
                    )}
                    {(currentUser?.is_trial && !currentUser?.is_paid) && (
                      <>
                        <button
                          onClick={() => {
                            window.open('https://billing.stripe.com/p/login/9AQ02h0xS9ba1BS7ss', '_blank');
                            setshowMyProfile(true);
                          }}
                          className="btn btn-custom w-100"
                        >
                          Complete Your Setup
                        </button>
                        <p
                          className="item"
                          style={{
                            fontSize: "smaller",
                            padding: 0,
                            marginLeft: "10px"
                          }}
                        >Add your payment method to confirm your premium subscription and enjoy uninterrupted access after your trial.</p>
                      </>
                    )}
                    {(!currentUser?.is_trial && !currentUser?.is_premium && !currentUser?.is_paid) && (
                      <button
                        onClick={() => {
                          setShowUpdagradeModal(true)
                        }}
                        href={'javascript:void(0);'}
                        className="btn btn-custom w-100">Get Subscription</button>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>

      )}
      {/* {currentUser?.role_id === 2 && (
        <div className="col-md-4">
          <a href={`https://buy.stripe.com/test_3cscP069U9txau4fYZ?client_reference_id=${currentUser?.id}`} target="_blank">
            <div className="premiumcard">
              <ul>
                <li>Unlimited RFPs (for 1 client)</li>
                <li>Customer Support</li>
                <li>Team Training</li>
              </ul>
              <div className="text-center">Upgrade Now</div>
              <div className="card-title">
                Upgrade To Premium
              </div>
            </div>
          </a>
        </div>
      )} */}
      <Modal
        id="UpgradeModal"
        isOpen={showUpgradeModal}
        toggle={() => setShowUpdagradeModal(!showUpgradeModal)}
        scrollable={true}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "16px 24px",
          }}
        >
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Get Subscription
          </Typography>
          <IconButton onClick={() => setShowUpdagradeModal(!showUpgradeModal)}>
            <CloseIcon />
          </IconButton>
        </div>
        <ModalBody>
          <UpgradePremium />
        </ModalBody>
      </Modal>
    </div >

  );
}
